:root {
  --black: #0f1115;
  --white: #fff;
}
.dark {
  --black: #fff;
  --white: #0f1115;
}

.clickable {
  @apply cursor-pointer font-medium;

  &.active {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: theme("colors.blue.600");
    text-decoration-thickness: 2px;
    text-underline-offset: 1px;
  }
}

.imageContainer > div {
  min-width: 100%;
}

.text-shadow {
  &:hover {
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

.shadow-light {
  box-shadow: 0 0 10px 1px rgba(14, 165, 233, 0.5);
}

.shadow-light-focusable {
  box-shadow: 0 0 8px 0.6px rgba(14, 165, 233, 0.5);
  &:focus {
    box-shadow: 0 2px 8px 1.8px rgba(154, 187, 202, 0.5);
  }
}

.shadow-error {
  box-shadow: 0 0 8px 0.6px rgba(248, 113, 113);
}

.shadow-error-strong {
  box-shadow: 0 0 12px 2px rgba(248, 113, 113);
}

.shadow-medium {
  box-shadow: 0 0 6px 2px rgba(56, 69, 75, 0.5);
}

.shadow-strong {
  box-shadow: 0 0 4px 8px rgba(14, 165, 233, 0.5);
}

.shadow-base {
  box-shadow: 1px 4px 6px -1px rgba(0, 0, 0, 0.13),
    0.5px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.drop-shadow-random {
  filter: drop-shadow(0 0 2.5px var(--color2));
}

.drop-shadow-random-strong {
  filter: drop-shadow(0 0 30px var(--color2));
}

.shadow-light-random {
  box-shadow: 0 0 9px 0.3px var(--color2);
}

.shadow-light-current {
  box-shadow: 0 0 9px 0.3px currentColor;
}

.shadow-medium-random {
  box-shadow: 3px 3px 8px 0.6px var(--darkColor2);
  &:hover {
    box-shadow: 0px 0px 6px 1px var(--darkColor2);
  }
}
.shadow-medium-random-fixed {
  box-shadow: 3px 3px 8px 0.6px var(--darkColor2);
}

.dark .shadow-medium-random {
  box-shadow: 3px 3px 8px 0.6px var(--color2);
  &:hover {
    box-shadow: 0px 0px 6px 1px var(--color2);
  }
}

.img-background {
  background: linear-gradient(to bottom right, var(--color1), var(--color2));
}

.background-modal {
  background-image: radial-gradient(rgba(0, 0, 0, 0) 1px, white 1px);
  background-size: 4px 4px;
  backdrop-filter: brightness(100%) blur(3px);
}

.dark .background-modal {
  background-image: radial-gradient(rgba(0, 0, 0, 0) 1px, black 1px);
}

.group-cart-el {
  @apply transition-transform duration-150 transform;
}

.group-cart:hover .group-cart-el {
  @apply -rotate-20;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 1.1s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
}

#imageWrapper span {
  position: inherit !important;
  height: 100% !important;
  & img {
    position: inherit !important;
    height: auto !important;
  }
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes reverse-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes ping-slow {
  75%,
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

div[data-radix-popper-content-wrapper] {
  display: flex;
  background-color: var(--white);
  width: 100%;
  max-width: 640px;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  min-width: 2px !important;

  @media (min-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}
