h1 {
  @apply text-3xl font-black sm:text-4xl;
  letter-spacing: 0.005em;
}

h2 {
  @apply text-2xl font-bold sm:text-3xl;
}

h3 {
  @apply text-xl pb-2 font-medium sm:text-2xl;
}

a {
  @apply cursor-pointer font-medium hover:text-random2-600 no-underline;
}

* {
  box-sizing: border-box;
}

.highlight {
  @apply hover:text-random2-600 underline;
  text-decoration-style: solid;
  text-decoration-color: var(--darkColor2);
  text-decoration-thickness: 2px;
  text-underline-offset: 1px;
  &-inverted {
    @apply hover:text-random2-300;
    text-decoration-color: var(--color2);
  }
}
.dark .highlight {
  @apply hover:text-random2-300;
  text-decoration-color: var(--color2);
  &-inverted {
    @apply hover:text-random2-600;
    text-decoration-color: var(--darkColor2);
  }
}

.dark a {
  @apply hover:text-random2-300;
}

.nightwind-prevent.highlight,
.nightwind-prevent-block .highlight {
  @apply hover:text-random2-600;
  text-decoration-color: var(--darkColor2);
}
