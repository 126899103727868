.background-height {
  // height: -moz-available; /* WebKit-based browsers will ignore this. */
  // height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  // height: fill-available;
  height: stretch;
}

.move-up {
  display: block;
  animation: MoveUp 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}

@keyframes MoveUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
